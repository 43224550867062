import { ApiService } from 'services';
import { createAction } from 'redux-actions';
import {
  modelName,
  FETCH_PENDING,
  FETCH_SUCCESSED,
  FETCH_BLOCKS_SUCCESSED,
  FETCH_PAYMENTS_SUCCESSED,
  FETCH_MINERS_SUCCESSED,
  FETCH_ACCOUNT_SUCCESSED,
  FETCH_ACCOUNT_FAILED,
  FETCH_COINS_SUCCESSED,
  FETCH_SINGLE_SUCCESSED,
  FETCH_TOTAL_AMOUNT_SUCCESSED, POST_SETTINGS_SUCCESSED, POST_SETTINGS_FAILED, UPDATE_SETTINGS, SETTINGS_SET_FOCUS,
} from './actions';

const fetchPending = createAction(FETCH_PENDING);
const fetchSuccessed = createAction(FETCH_SUCCESSED);
const fetchSingleSuccessed = createAction(FETCH_SINGLE_SUCCESSED);
const fetchCoinsSuccessed = createAction(FETCH_COINS_SUCCESSED);
const fetchAccountFailed = createAction(FETCH_ACCOUNT_FAILED);
const fetchBlcoksSuccessed = createAction(FETCH_BLOCKS_SUCCESSED);
const fetchPaymentsSuccessed = createAction(FETCH_PAYMENTS_SUCCESSED);
const fetchMinersSuccessed = createAction(FETCH_MINERS_SUCCESSED);
const fetchAccountSuccessed = createAction(FETCH_ACCOUNT_SUCCESSED);
const fetchTotalAmountSuccessed = createAction(FETCH_TOTAL_AMOUNT_SUCCESSED);
const postSettingsSuccessed = createAction(POST_SETTINGS_SUCCESSED);
const postSettingsFailed = createAction(POST_SETTINGS_FAILED);
const updateSettingsAction = createAction(UPDATE_SETTINGS);
const settingsSetFocusAction = createAction(SETTINGS_SET_FOCUS);

export const fetchHomeStats = () => async dispatch => {
  try {
    const res = await ApiService.get('/api/stats');

    await dispatch(fetchTotalAmountSuccessed(res.totalPayments));
  } catch (error) {}
};

export const fetchHomeCoins = () => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get('/api/home');

    await dispatch(fetchCoinsSuccessed(res));
  } catch (error) {}
};

export const fetchCoins = coins => async (dispatch, getState) => {
  await dispatch(fetchPending());
  let coinsArray = [];
  const resPromises = [];

  try {
    const state = getState()[modelName];
    for (let coin of coins) {
      const includeLogo = !state.list.find(c => c.slug === coin.name);
      resPromises.push(ApiService.get(
        `/api/coin/${coin.name}?include_logo=${Number(includeLogo)}`
      ));
    }

    for (const res of await Promise.allSettled(resPromises)) {
      if (res.status === "rejected") {
        console.error(res.reason);
        continue;
      }
      const newCoin = res.value?.coin;
      const oldCoin = state.list.find(c => c.slug === newCoin.slug);
      if (oldCoin) {
        coinsArray.push({...newCoin, logo: oldCoin.logo, servers: oldCoin.servers});
      } else {
        coinsArray.push(newCoin);
      }
    }

    await dispatch(fetchSuccessed(coinsArray));
  } catch (error) {
    console.error(error);
  }
};

export const fetchSingleCoin = slug => async (dispatch, getState) => {
  await dispatch(fetchPending());
  const state = getState()[modelName];
  const includeLogo = !(state.data && state.data.slug === slug && state.data.servers && state.data.logo);

  try {
    const res = await ApiService.get(`/api/coin/${slug}?include_logo=${Number(includeLogo)}`);
    if (!includeLogo) {
      res.coin.servers = [...state.data.servers];
      res.coin.logo = state.data.logo;
    }
    await dispatch(
      fetchSingleSuccessed({
        ...res.coin,
        source: res.source,
      })
    );
  } catch (error) {}
};

export const fetchBlocks = (slug) => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get(`/api/blocks/${slug}`);

    dispatch(
      fetchBlcoksSuccessed({
        ...res.blocks,
        source: res.source,
      })
    );
  } catch (error) {}
};

export const fetchPayments = (slug) => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get(`/api/${slug}/payments`);

    dispatch(
      fetchPaymentsSuccessed({
        ...res.payments,
        source: res.source,
      })
    );
  } catch (error) {}
};

export const fetchMiners = (slug) => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get(`/api/${slug}/miners`);

    dispatch(
      fetchMinersSuccessed({
        ...res.miners,
        source: res.source,
      })
    );
  } catch (error) {}
};

export const fetchAccount = (id, coin) => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get(`/api/account/${coin.name ?? coin}/${id}`);

    dispatch(
      fetchAccountSuccessed({
        ...res.account,
        source: res.source,
      })
    );
    return true;
  } catch (error) {
    dispatch(fetchAccountFailed());
    return false;
  }
};

export const clearAccount = () => dispatch =>
  dispatch(fetchAccountSuccessed(null));

export const postSettings = (id, slug, payload) => async dispatch => {
  dispatch(fetchPending());
  try {
    await ApiService.post(`/api/account/${slug}/${id}`, { ...payload }, null);
    dispatch(postSettingsSuccessed());

    return true;
  } catch (e) {
    console.error(e);
    if (e instanceof Response) {
      if (e.headers.get('content-type').includes('json')) {
        dispatch(postSettingsFailed({error: (await e.json()).error}));
      } else {
        dispatch(postSettingsFailed({error: (await e.text())}))
      }
    } else {
      dispatch(postSettingsFailed({ error: e.toString() }));
    }
  }
  return false;
}

export const postSettingsError = ({ error, info }) => dispatch => {
  if (error) {
    dispatch(postSettingsFailed({ error, info, isInternal: true }));
  } else {
    dispatch(postSettingsSuccessed({reset: true}));
  }
}

export const updateSettings = settings => dispatch => {
  dispatch(updateSettingsAction(settings))
}

export const settingsSetFocus = focus => dispatch => {
  dispatch(settingsSetFocusAction(focus));
}