import { lazy } from 'react';
import { suspenseEnhancer } from 'hocs';

const Home = lazy(() => import('./modules/home/containers/Home'));
const Pools = lazy(() => import('./modules/home/containers/Pools'));
const QuestionAnswer = lazy(() =>
  import('./modules/home/containers/QuestionAnswer')
);
const Pool = lazy(() => import('./modules/pool/containers'));

const routes = [
  {
    path: '/',
    exact: true,
    public: true,
    component: suspenseEnhancer(Home),
  },
  {
    path: '/pools',
    exact: true,
    public: true,
    component: suspenseEnhancer(Pools),
  },
  {
    path: '/question-answer',
    exact: true,
    public: true,
    component: suspenseEnhancer(QuestionAnswer),
  },
  {
    path: '/tech-support',
    exact: true,
    public: true,
    component: suspenseEnhancer(QuestionAnswer),
  },
  {
    path: '/pool/:slug',
    public: false,
    component: suspenseEnhancer(Pool),
  },
];

export default routes;
